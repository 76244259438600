<!--升级认证发布人-->
<template>
    <div class="page">
        <div class="my-top">
            <div class="insert-picture">
                <div class="pic-content">
                  <van-uploader :deletable="true" v-model="fileList" :after-read="afterRead" @delete="deleteImg" :max-count="1"/>
                    <div class="info-right">
                        <van-field v-model="form.name" required name="姓名" label="姓名" v-bind:disabled="(form.userState=='SUCCESS'||form.businessState=='SUCCESS')"
                                placeholder="请输入姓名" :rules="[{ required: true, message: '请输入姓名' }]"
                        />
                        <van-field v-model="form.age" required name="年龄" label="年龄" placeholder="年龄"
                                :rules="[{ required: true, message: '请填写年龄' }]"
                        />
                    </div>
                </div>
                <div>
                    <van-field v-model="form.address" required
                            clearable readonly  @click="showArea=true" size="large" label="家庭住址" placeholder="请填写家庭住址"
                            :rules="[{ required: true, message: '请填写家庭住址' }]"/>

                </div>
                <div>
                  <van-field v-model="form.phone" clearable size="large" required label="手机号" type="tel" maxlength="11" placeholder="请填写手机号"
                      :rules="[{ required: true, message: '请填写手机号' }]"/>
                </div>
              <!--擅长工种-->
              <div class="CheckGroupBox">
                <label class="label-required">擅长工种</label>
                <CustomCheckGroup v-model="form.workType" :max-checked="4" :items="workTypes" ></CustomCheckGroup>
              </div>

              <van-field v-model="form.gender==0?'男':'女'" clearable readonly size="large" label="性别"  right-icon="arrow-down" placeholder="请选择性别" @click="showSex=true"  />
              <van-field v-model="form.isHeight" clearable readonly size="large" label="可否登高"  right-icon="arrow-down" placeholder="是否可以登高" @click="showIsHeight=true"  />


              <!--自我介绍-->
              <div class="CheckGroupBox">
                <label>自我介绍</label>
                <van-field
                    v-model="form.introduce"
                    rows="2"
                    autosize
                    label=""
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入自我介绍"
                    show-word-limit
                />
              </div>

              <!--资格证书-->
              <div class="CheckGroupBox">
                <label>资格证书</label>
                <van-uploader :deletable="true" v-model="certificateList" :after-read="afterReadCertificate" :before-delete="deleteImgCertificateBefore" @delete="deleteImgCertificate" :max-count="4"/>
              </div>
              <div style="text-align: right;font-size: 12px;color: #4d4d4d;padding-right: 30px;">请上传电工证、登高证等相关证书</div>
              <div style="text-align: right;font-size: 13px;color: red;padding-right: 30px;">    证书只用于审核，不会对外展示</div>
              <!--收款码-->
<!--              <div class="CheckGroupBox">-->
<!--                <label class="payImgLabel">收款码</label>-->
<!--                <van-uploader :deletable="true" v-model="payImgList" :after-read="afterReadPayImg" @delete="deletePayImg" :max-count="1"/>-->
<!--              </div>-->
<!--              <div class="CheckGroupBox" style="min-height: 100px">-->
<!--              </div>-->

                <div class="basic-info">
                    <van-button :disabled="submitDisabled" round block type="info" native-type="submit" class="save-btn" @click="save">保存信息</van-button>
                </div>
            </div>

        </div>

        <!-- 弹出选择 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    :default-index="0"
                    cancel-button-text="清除已选"
            />
        </van-popup>

      <van-dialog v-model="showArea" title="" :show-cancel-button="false" :showConfirmButton="false">
        <van-area title="选择地区" :area-list="areaList" @confirm="confirmArea" />
<!--        <van-area title="选择地区" @cancel="showArea=false" :area-list="areaList" v-show="showArea" @confirm="confirmArea" />-->
      </van-dialog>

      <van-action-sheet v-model="showIsHeight" :actions="insurances" @select="onSelectIsHeight" />

      <van-action-sheet v-model="showSex" :actions="sexs" @select="onSelectSex" />

      <van-popup v-model="tipShow" class="info-popup">
          <van-icon name="cross"  color="#7A8CA2" class="close-symbol" @click="tipShow = false"/>
          <div class="first-line">完善信息 快人一步</div>
<!--        <div class="first-line"></div>-->
<!--          <span class="second-line">-->
<!--              完善信息可获得元宝奖励-->
<!--          </span>-->
          <div class="third-line">
              完善必填项可获得<span>50元宝</span><span>{{ integral.注册奖励 }}积分</span>
          </div>
          <div class="third-line">
            非必填项完善越多奖励的元宝越多
          </div>
          <div class="third-line">
            最多可获得<span>{{ treasure.注册奖励 }}元宝</span>
          </div>
          <div class="footer">
              <van-button round type="info" block class="footer-btn" @click="tipShow = false">立即完善</van-button>
          </div>
      </van-popup>

      <van-dialog v-model="showSuccess" title="提交成功，客服审核中" style="text-align: center" @confirm="go()">
        <div style="font-size: 16px;width: 100%;text-align: center;">结果,将通过“活可多”公众号发送给您</div>
        <div style="font-size: 16px;width: 100%;text-align: center;">请长按下方二维码，关注“活可多”公众号</div>
        <img width="280px" src="@/assets/images/qrcode.jpg" />
      </van-dialog>

    </div>


</template>

<script>
    import {        upload,      editUserf,      me    } from "../../api/login";
    import {Notify, ActionSheet, Dialog} from 'vant';
    import store from '../../store'
    import {        mapMutations    } from 'vuex'
    import {SET_TOKEN, SET_USER} from '../../const.js'
    import { areaList } from '../../api/area';
    import {getDicts,getDictsMap} from "../../api/dict";
    import {getUserInfo} from "../../api/user";
    import {compressImage} from "../../utils/CompressImageUtils";
    export default {
        data() {
            return {
              submitDisabled: false,
              integral: {
                注册奖励: 0
              },
              treasure: {
                注册奖励: 0
              },
              showSex:false,
              showSuccess: false,
              tipShow:false,
              areaList: areaList,
              showArea:false,
              showInsurance:false,
              showIsHeight: false,
              back: 0,
              show: false,
              sexName: '男',
              type: '1',
              workTypes:[
                {
                  label:"焊工",
                  value:"焊工"
                },{
                  label:"电工",
                  value:"电工"
                }
              ],
              sexs: [{name: '男'}, {name: '女'}],
              insurances: [{name: '是'}, {name: '否'}],
              form: {
                id: undefined,
                avatar: undefined,
                gender: undefined,
                phone: undefined,
                name: undefined,
                address: undefined,
                advantage: undefined,
                isHeight: undefined,
                introduce: undefined,
                futureService: undefined,
                age: undefined,
                cardBack: undefined,
                cardJust: undefined,
                certificate: undefined,
                insurance: undefined,
                workAge: undefined,
                cardNo: undefined,
                workType: [],
                payImg: undefined
              },
              fileList: [],//头像
              payImgList: [],//收款码
              certificateList:[],//资格证书
              cardList:[],//身份证
                insurance: undefined,
                showPicker: false,
                columns: ["是", "否"],
              user: {}
            }
        },
        mounted() {
          this.user = JSON.parse(`${store.state.user}`)
          if (!this.user.phone){
            // this.tipShow=true;
            this.form.id=this.user.id
          }else{
            this.form=this.user
          }
          this.loadUserInfo()
          this.loadDictData()
        },
        methods: {
          ...mapMutations([SET_USER]),
          async loadDictData() {
            this.workTypes = await getDicts({ name: "工人类型" })
            this.integral = await getDictsMap({ name: "积分类型" })
            this.treasure = await getDictsMap({ name: "元宝类型" })
          },
          async loadUserInfo() {
            let r = await getUserInfo();
            this.user = r.data
            this.form=this.user
            this.fileList.push({url:this.user.avatar,file:new File([],'',{})})
            if (this.user.payImg){
              this.payImgList.push({url:this.user.payImg,file:new File([],'',{})})
            }
            if (this.user.certificate.length){
              this.user.certificate.forEach(c=>{if(c){this.certificateList.push({url:c,file:new File([],'',{})})}})
            }
          },
          confirmArea(e){
            let sheng = e[0];
            let city = e[1];
            let qu = e[2];
            this.form.address = sheng.name +" "+city.name+" "+qu.name
            this.showArea=false;
          },
            onConfirm(value) {
                this.insurance = value;
                this.showPicker = false;

            },
            onCancel() {
                this.insurance = undefined;
                this.showPicker = false;
            },

          onSelectIsHeight(item) {
            this.showIsHeight = false;
            this.form.isHeight = item.name
          },
          onSelectSex(item) {
            this.showSex = false;
            this.form.gender = item.name=='男'?0:1
          },

          deleteImgCardListBefore(e,name){
            if (name.index==0){
              this.form.cardJust="";
            }else{
              this.form.cardBack="";
            }
            this.cardList.splice(name.index,1)
          },
          async afterReadCard(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            if (this.cardList.length==1){
              this.form.cardJust=r.data.url
            }else {
              this.form.cardBack=r.data.url
            }
          },

          //资格证书
          deleteImgCertificate(e){
            console.info("deleteImg", e)
          },
          async afterReadCertificate(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            this.form.certificate.push(r.data.url)
            console.info("afterReadCertificate",this.certificateList)
          },
          deleteImgCertificateBefore(e,name){
            console.info("deleteImgCertificateBefore", e,name)
            this.form.certificate.splice(name.index,1)
            this.certificateList.splice(name.index,1)
            console.info("deleteImgCertificateBefore", this.form)
          },

          deletePayImg(){
            console.info("deleteImg", )
            this.payImgList= []
            this.form.payImg='';
          },
          async afterReadPayImg(file) {
            compressImage(file.content).then(result => {
              console.log('压缩后的结果', result); // result即为压缩后的结果
              console.log('压缩前大小', file.file.size);
              console.log('压缩后大小', result.size);
              if (result.size > file.file.size){
                console.log('上传原图');
                //压缩后比原来更大，则将原图上传
                this._uploadPayImg(file.file,file.file.name);
              } else {
                //压缩后比原来小，上传压缩后的
                console.log('上传压缩图');
                this._uploadPayImg(result,file.file.name)
              }
            })
          },
          async _uploadPayImg(file,filename){
            const forms = new FormData();
            forms.append("file", file,filename); // 获取上传图片信息
            let r = await upload(forms)
            this.form.payImg = r.data.url
            console.info("payImg Upload:",this.payImgList)
          },

          deleteImg(){
            console.info("deleteImg", )
            this.fileList= []
            this.form.avatar='';
          },
          async afterRead(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            this.form.avatar = r.data.url
          },
          async save() {

            console.info("save",this.form)
            if (!this.form.name) {Notify({type: 'warning',message: '请输入姓名'});return false;}
            if (!this.form.address) {Notify({type: 'warning',message: '请选择住址'});return false;}
            if (!this.form.phone) {Notify({type: 'warning',message: '请输入手机号'});return false;}
            // if (this.form.phone.length<11) {Notify({type: 'warning',message: '手机号不够11位'});return false;}
            if(!(/^1[3456789]\d{9}$/.test(this.form.phone))){
              Notify({type: 'warning',message: '手机号格式错误'});return false;
            }
            if (!this.form.age) {Notify({type: 'warning',message: '请输入年龄'});return false;}
            if (this.form.workType.length<1) {Notify({type: 'warning',message: '请选择擅长工种'});return false;}
            // if (this.payImgList.length<1){Notify({type: 'warning',message: '请上传收款码'});return false;}
            if (this.certificateList.length<1){
              Dialog.confirm({
                confirmButtonText:'上传证书',
                cancelButtonText:'没有证书',
                title: '提醒',
                message: '您是否有电工证、登高证等相关证书？\n' +
                    '上传证书照片,可领取更大红包\n' +
                    '马上去上传吧',
              })
                  .then(() => {
                      console.info("上传证书")
                  })
                  .catch(() => {
                    this.saveM()
                  });
            }else{
              this.saveM()
            }
          },
          async saveM(){
            this.submitDisabled=true;
            let r = await editUserf(this.form)
            this.submitDisabled=false;
            if (r.errno!=0){
              Notify({ type: 'error', message: r.errmsg });
            }else {
              console.info("保存成功",r)
              this.setUser(JSON.stringify(r.data))
              this.showSuccess=true;
            }
          },
          go(){
            // this.showSuccess=true;
            if(this.back){
              history.back();
            }else{
              this.$router.push({
                path: '/home'
              })
            }
          }

        },
        computed: {}
    }

</script>

<style lang="less" scoped>

.payImgLabel::before {
  position: absolute;
  padding-top: 6px;
  left: 8px;
  color: #ee0a24;
  font-size: 14px;
  content: '*';
}

.CheckGroupBox{
  display: flex;
  padding: 15px 5px 5px 15px;
  label{
    padding-top: 5px;
    color: #646566;
    text-align: left;
    word-wrap: break-word;
    width: 5.3em;
    font-size: 16px;
  }
}

.label-required::before {
  padding-top: 6px;
  position: absolute;
  left: 8px;
  color: #ee0a24;
  font-size: 14px;
  content: '*';
}

    .my-top {
        height: 120px;
        width: 100%;
        background: linear-gradient(to right, #1083FC, #08ADFD);
        border-radius: 0 0 25px 25px;

        .insert-picture {
            max-width: 700px;
            border-radius: 7px;
            width: 94%;
            margin: 0;
            height: 100%;
            min-height: 860px;
            background: #FFFFFF;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 30px);
            padding-bottom: 15px;
            .pic-content {
                margin-top: 15px;
                margin-left: 10px;
                display: flex;
                justify-content: space-around;

                .info-right {
                    width: 70%;
                }

            }

            .basic-info {
                //position: fixed;
                //bottom: 10px;
              margin-top: 15px;
              margin-bottom: 15px;
                max-width: 720px;
                width: 100%;
            }
        }
    }

    .info-popup{
        max-width: 700px;
        width: 80%;
        height: 317px;
        border-radius: 10px;
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("../../assets/images/wanshanxinxitankuang.png");
        color: #212121 ;
        margin-top: 4%;
        text-align: center;
        .close-symbol{
            float: right;
            margin: 10px 10px 0 0;
            font-size: 14px;
        }
        .first-line {
            margin-top: 38%;
            margin-left: 15px;
            font-size: 16px;
            font-weight: 600;
            color: #212121;
            padding: 5px;
        }
        .second-line{
            color: #8B8D90;
            font-size: 10px;
        }
        .third-line{
            padding: 5px;
            color: #8B8D90;
            font-size: 12px;
            span{
                color: #fea052;
                font-size:14px;
            }
        }
        .footer {
            position: fixed;
            bottom: 20px;
            max-width: 720px;
            width: 75%;

            .footer-btn{
                text-align: center;
                margin-left: 16%;
            }
        }
    }


</style>
